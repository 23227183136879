import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';

import LoadingSpinner from 'components/LoadingSpinner';
import { setSpecificationsData } from 'store/entities/data';

import './styles.scss';
import { GradeCard } from 'components/Cards';

const GradeSelection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const { specificationsData } = useSelector(state => state.data);

  const { id: model } = queryString.parse(location.search);

  const { isFetching, refetch: fetchData } = useQuery(
    ['specifications', model],
    async () =>
      axios.get('/builder/specifications', {
        params: {
          'brand.name': 'toyota',
          'model.id': model,
          isActive: true,
        },
      }),
    {
      enabled: false,
      onSuccess: res => dispatch(setSpecificationsData(res.data)),
    }
  );

  useEffect(() => {
    if (model) fetchData();
    else window.location.replace('https://toyota.wess.lv/');
  }, [model, fetchData]);

  return (
    <>
      {isFetching && <LoadingSpinner />}
      <Container style={{ minHeight: '70vh' }}>
        <h2 className="py-4">{t('first_step_title')}</h2>
        <p className="grade-description">{t('grade_selection_descr')}</p>
        <Row>
          {Object.keys(specificationsData).map(key => {
            const data = [...specificationsData[key]].sort((a, b) => a.price - b.price);

            if (!data.length) return null;
            const mainGrade = data[0];
            return (
              <Col xs={12} sm={6} md={4} lg={3} key={mainGrade.eic}>
                <Row>
                  <Col xs={12}>
                    <GradeCard gradeData={mainGrade} />
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
        {!isFetching && Object.keys(specificationsData).length === 0 && (
          <Row>
            <Col>
              <span>Autosid ei leitud</span>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default GradeSelection;
